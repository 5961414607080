<template>
  <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      y="0.826538"
      width="14"
      height="14"
      rx="7"
      fill="#FF612F"
    />
    <path
      d="M7 8.69177V3.45154M7 12.2015V11.889"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
